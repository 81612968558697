import jwt from '@/auth/jwt/useJwt';

/* Payroll */

export function payrollList(condition) {
  return jwt.axiosIns.get('/payrolls', {
    params: {
      ...condition,
    },
  });
}
export function payrollDetail(id) {
  return jwt.axiosIns.get(`/payrolls/${id}`);
}
export function payrollDetailContractList(id, params) {
  return jwt.axiosIns.get(`payrolls/${id}/contracts`, {
    params,
  });
}
export function cancelPayroll(id) {
  return jwt.axiosIns.post(`payrolls/${id}/cancel`);
}
export function payrollDetailRepaymentHistory(id) {
  return jwt.axiosIns.get(`payrolls/${id}/repayment-history`);
}
export function payrollPaymentList(condition) {
  return jwt.axiosIns.get('/payrolls/repayments', {
    params: {
      ...condition,
    },
  });
}
export function getLinkPayroll(id) {
  return jwt.axiosIns.post(`/payrolls/${id}/download-attachment`, null, {
    responseType: 'blob',
  });
}

export function approvePayroll(id) {
  return jwt.axiosIns.post(`/payrolls/${id}/approve`);
}

export function investPayroll(id, data) {
  return jwt.axiosIns.post(`payrolls/${id}/invest`, data);
}

export function disbursementPayroll(id, data) {
  return jwt.axiosIns.post(`payrolls/${id}/disburse`, data);
}

export function repaymentConfirm(id, data) {
  return jwt.axiosIns.post(`payrolls/repayments/${id}/repayment-confirm`, data);
}

export function repaymentCancel(id) {
  return jwt.axiosIns.post(`payrolls/repayments/${id}/repayment-reject`);
}
export function reGenContract(id) {
  return jwt.axiosIns.post(`payrolls/${id}/re-gen-contract`);
}

export function updateRepaymentSchedule(payrollId, data) {
  return jwt.axiosIns.post(`/payrolls/repayment-schedule/${payrollId}`, {
    repaymentSchedule: data,
  });
}

/* Company */

export function companyList(condition) {
  return jwt.axiosIns.get('companies', {
    params: {
      ...condition,
    },
  });
}

export function companyDetail(id) {
  return jwt.axiosIns.get(`companies/${id}`);
}

export function companyVerify(id) {
  return jwt.axiosIns.post(`companies/${id}/verify`);
}

export function createCompany(data) {
  return jwt.axiosIns.post('companies', data);
}

export function updateCompany(companyId, data) {
  return jwt.axiosIns.put(`companies/${companyId}`, data);
}
export function deleteCompany(companyId) {
  return jwt.axiosIns.delete(`companies/${companyId}`);
}

export function getListAccountCompany(condition) {
  return jwt.axiosIns.get('employees', {
    params: {
      ...condition,
    },
  });
}

/* Employees */

export function getDetailAccountCompany(accountId) {
  return jwt.axiosIns.get(`employees/${accountId}`);
}
export function createAccountCompany(data) {
  return jwt.axiosIns.post('employees', data);
}

export function updateAccountCompany(accountId, data) {
  return jwt.axiosIns.put(`employees/${accountId}`, data);
}

export function deleteAccountCompany(accountId) {
  return jwt.axiosIns.delete(`employees/${accountId}`);
}

export function getProvinces() {
  return jwt.axiosIns.get('employees/provinces');
}

export function exportStatementContract(id) {
  return jwt.axiosIns.post(`payrolls/transactions/${id}/export-statement-file`, null, {
    responseType: 'blob',
  });
}
export function requestPayment(data, id) {
  return jwt.axiosIns.post(`payrolls/${id}/repayments`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
