<template>
  <b-button :variant="variant" :disabled="loading" @click="handle">
    <b-spinner v-if="loading" :style="spinerStyle" :variant="spinerVariant" />
    <slot v-else />
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue';

export default {
  name: 'LoaderButton',
  components: {
    BButton,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
    },
    spinerVariant: {
      type: String,
      default: 'light',
    },
    spinerStyle: {
      type: String,
      default: '',
    },
  },
  methods: {
    handle() {
      this.$emit('click');
    },
  },
};
</script>
