<template>
  <div
    :class="{
      'text-truncate': truncate,
    }"
    style="margin: 0.5rem; margin-bottom: 0"
    :style="[styleObject]"
  >
    <a v-if="url" v-b-tooltip.hover.bottom="label" :href="url" target="blank" :title="label">
      {{ label }}
    </a>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: 'HyperLink',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleObject() {
      return this.truncate ? { maxWidth: '220px' } : {};
    },
  },
};
</script>
