<template>
  <div class="contract-view">
    <b-row class="basic-infor">
      <b-col xl="9" cols="12" md="12" class="pr-0">
        <b-card class="mb-0 h-100">
          <b-row>
            <b-col xl="5">
              <div class="d-flex align-items-start">
                <b-avatar
                  ref="previewEl"
                  :src="companyDetail.logoImage"
                  :text="avatarText('viecco')"
                  size="80px"
                  variant="light-primary"
                  rounded
                />
                <div class="ml-1">
                  <h4>{{ companyDetail.name }}</h4>
                  <p>{{ companyDetail.shortName }}</p>
                  <p>{{ companyDetail.email }}</p>
                </div>
              </div>
            </b-col>
            <b-col xl="7">
              <div class="d-flex">
                <p class="flex-1 font-weight-bold mr-1">Công ty:</p>
                <p class="flex-2">{{ companyDetail.name }}</p>
              </div>
              <div class="d-flex py-1">
                <p class="flex-1 font-weight-bold mr-1">Mã số thuế:</p>
                <p class="flex-2">{{ companyDetail.taxCode }}</p>
              </div>
              <div class="d-flex">
                <p class="flex-1 font-weight-bold mr-1">Địa chỉ:</p>
                <p class="flex-2">{{ companyDetail.address }}</p>
              </div>
              <div class="d-flex py-1">
                <p class="flex-1 font-weight-bold mr-1">Ngày tạo:</p>
                <format-time :time="companyDetail.createdAt" />
              </div>
              <div class="d-flex">
                <p class="flex-1 font-weight-bold mr-1">Sản phẩm:</p>
                <div>
                  <div v-for="(product, idx) in companyDetail.products" :key="idx">
                    <div
                      v-if="
                        !product.term ||
                        !product.duration ||
                        !product.feeRate ||
                        !product.productType
                      "
                    ></div>
                    <div v-else>
                      Kì hạn: {{ product.term }}; Tần suất: {{ $t(product.duration) }}; Phần trăm
                      phí: {{ product.feeRate }}%; sản phẩm {{ $t(product.productType) }}
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col xl="3" sm="6">
        <b-card class="mb-0 h-100">
          <div class="d-flex align-items-start justify-content-between mb-1">
            <!-- <div class="d-flex">
              <div
                class="icon-dollar mr-1 rounded justify-content-center d-flex align-items-center"
              >
                <feather-icon icon="DollarSignIcon" size="16" />
              </div>
              <div>
                <p>5.000.000 VND</p>
                <p>Tổng tiền ứng</p>
              </div>
            </div> -->
          </div>
          <div class="mt-2">
            <b-button
              v-b-modal.modal-update-company
              variant="primary"
              class="p-0 py-1 w-50 w-100"
              type="submit"
            >
              Chỉnh sửa
            </b-button>
          </div>
          <div class="mt-1 d-flex">
            <!-- <b-button
              v-b-modal.modal-delete-company
              variant="danger"
              class="p-0 py-1 w-50 mr-1"
              type="submit"
            >
              Xoá
            </b-button> -->
            <b-button
              v-if="companyDetail.isActive"
              variant="secondary"
              class="p-0 py-1 w-50 w-100"
              type="submit"
              @click="() => handleUpdateActive(false)"
            >
              Huỷ kích hoạt
            </b-button>
            <b-button
              v-else
              variant="outline-primary"
              class="p-0 py-1 w-50 mr-1 w-100"
              type="submit"
              @click="() => handleUpdateActive(true)"
            >
              Kích hoạt
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-update-company"
      title="Chỉnh sửa công ty"
      cancel-title="Huỷ"
      size="lg"
      @ok="hanldeUpdateCompany"
    >
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Tên công ty</label>
          <b-form-input v-model="companyName" class="d-inline-block mr-1" />
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Tên viết tắt</label>
          <b-form-input v-model="shortName" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Mã số thuế</label>
          <b-form-input v-model="taxCode" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Địa chỉ</label>
          <b-form-input v-model="address" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <p class="my-1">Sản phẩm</p>
      <b-row v-for="(products, idx) in listProduct" :key="idx" class="align-items-center">
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Kì hạn</label>
          <b-form-input
            class="d-inline-block"
            type="number"
            :value="products.term"
            @input="(val) => updateProduct(val, 'term', idx)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Tần suất</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="durationOptions"
            :value="products.duration"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => updateProduct(val, 'duration', idx)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2 relative">
          <label>Phần trăm phí</label>
          <b-form-input
            class="d-inline-block pr-2"
            type="number"
            :value="products.feeRate"
            @input="(val) => updateProduct(val, 'feeRate', idx)"
          />
          <span class="absolute-percent">%</span>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Sản phẩm</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productTypeOptions"
            :value="products.productType"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => updateProduct(val, 'productType', idx)"
          />
        </b-col>
        <div>
          <label>Kích hoạt</label>
          <b-form-checkbox
            switch
            :checked="products.isActive"
            @input="(val) => updateProduct(val, 'isActive', idx)"
          />
        </div>

        <feather-icon
          v-if="!products.id"
          icon="Trash2Icon"
          class="text-danger mt-2"
          size="20"
          role="button"
          @click="removeProduct(idx)"
        />
      </b-row>
      <div
        class="justify-content-center d-flex align-items-center border-bottom mt-1"
        style="width: fit-content; margin: 0 auto"
      >
        <p role="button" @click="addProduct">Thêm sản phẩm</p>
        <feather-icon icon="PlusIcon" />
      </div>
    </b-modal>
    <b-modal
      id="modal-delete-company"
      title="Xoá Công ty"
      ok-title="Xác nhận"
      cancel-title="Huỷ"
      @ok="handleDeleteCompany"
    >
      <p>Bạn có chắc chắn muốn xoá Công ty này?</p>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BButton,
  BTable,
  BMedia,
  BModal,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import { avatarText } from '@core/utils/filter';
import { useRouter, randomId } from '@core/utils/utils';
import { updateCompany, deleteCompany } from '@/apis/apiPayroll';
import FormatTime from '@core/components/format-time/FormatTime.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';

export default {
  components: {
    VueApexCharts,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BButton,
    BTable,
    BMedia,
    BModal,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    FormatTime,
    vSelect,
  },
  setup() {
    const router = useRouter();
    const { id } = router.route.value.params;
    return {
      id,
    };
  },
  props: {
    refetchData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
      companyName: '',
      shortName: '',
      taxCode: '',
      address: '',
      listProduct: [],
      durationOptions: [
        { label: 'Ngày', value: 'day' },
        { label: 'Tuần', value: 'week' },
        { label: 'Tháng', value: 'month' },
      ],
      productTypeOptions: [
        { label: 'EWA', value: 'EWA' },
        { label: 'Payroll', value: 'PAYROLL' },
      ],
      isActive: true,
    };
  },
  computed: {
    ...mapGetters({
      companyDetail: 'app-payroll/getCompanyDetailInfor',
    }),
  },
  watch: {
    companyDetail(val) {
      this.companyName = val.name;
      this.shortName = val.shortName;
      this.taxCode = val.taxCode;
      this.address = val.address;
      this.listProduct = val.products;
    },
  },
  methods: {
    handleUpdateActive(val) {
      updateCompany(this.id, {
        isActive: val,
      })
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thành công ',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thất bại ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    hanldeUpdateCompany(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.listProduct.some((item) => !item.term || !item.duration || !item.feeRate)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dữ liệu không hợp lệ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Sản phẩm không được để trống',
          },
        });
        return;
      }
      const productTypeCompany = this.listProduct.filter((item) => item.productType === 'EWA');
      if (productTypeCompany.length > 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dữ liệu không hợp lệ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Chỉ được 1 sản phẩm EWA',
          },
        });
        return;
      }
      if (
        this.listProduct.some(
          (item) =>
            (item.term > 1 || item.term <= 0) &&
            item.productType === 'EWA' &&
            item.duration === 'month',
        )
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dữ liệu không hợp lệ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Sản phẩm EWA chỉ được kì hạn 1 tháng',
          },
        });
        return;
      }
      this.listProduct.forEach((item) => {
        if (item.isActive === undefined) {
          item.isActive = true;
        }
        if (!item.id) {
          item.id = randomId();
        }
      });
      updateCompany(this.id, {
        name: this.companyName,
        address: this.address,
        taxCode: this.taxCode,
        products: this.listProduct,
        shortName: this.shortName,
        isActive: true,
      })
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Cập nhật thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.$bvModal.hide('modal-update-company');
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
          this.$bvModal.hide('modal-update-company');
        });
    },
    handleDeleteCompany() {
      deleteCompany(this.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Xoá công ty thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: constRouter.PAYROLL_COMPANY_LIST.name,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xoá thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    addProduct() {
      this.listProduct.push({
        term: '',
        duration: '',
        feeRate: '',
        productType: '',
        isActive: true,
      });
    },
    removeProduct(idx) {
      this.listProduct.splice(idx, 1);
    },
    updateProduct(val, key, idx) {
      this.listProduct[idx][key] = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.contract-view {
  .basic-infor {
    .ava {
      width: 100px;
      height: 100px;
    }
    .date-create-contract {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .status-contract {
      padding: 5px 0;
      background-color: rgba(0, 207, 232, 0.12);
      color: #00cfe8;
      margin-bottom: 5px;
    }
    .icon-dollar {
      width: 35px;
      background-color: rgba(255, 159, 67, 0.2);
      color: #ff9f43;
    }
  }
}
.relative {
  position: relative;
}
.absolute-percent {
  position: absolute;
  bottom: 8px;
  right: 20px;
}
</style>
