<template>
  <div v-if="url" class="mt-1">
    <a :href="url">
      <img :src="url" :width="width" style="border-style: double" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'MinimizeImage',
  props: {
    url: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 100,
    },
  },
};
</script>
