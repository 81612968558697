<template>
  <div>
    <payroll-company-detail-header :refetch-data="loadData" />
    <b-row class="basic-infor">
      <b-col cols="12" md="6" class="pr-0">
        <payroll-company-detail-list-account :province-options="provinceOptions" />
      </b-col>
      <b-col cols="12" md="6">
        <payroll-company-detail-info :province-options="provinceOptions" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import store from '@/store';
import router from '@/router';
import { onUnmounted, ref } from '@vue/composition-api';
import { getProvinces } from '@/apis/apiPayroll';
import { BRow, BCol } from 'bootstrap-vue';
import payrollCompanyDetailHeader from './payrollCompanyDetailHeader.vue';
import payrollCompanyDetailListAccount from './payrollCompanyDetailListAccount.vue';
import payrollCompanyDetailInfo from './payrollCompanyDetailInfo.vue';
import payrollStoreModule from '../../payrollStoreModule';

export default {
  components: {
    payrollCompanyDetailHeader,
    payrollCompanyDetailListAccount,
    payrollCompanyDetailInfo,

    BRow,
    BCol,
  },
  created() {
    getProvinces()
      .then((res) => {
        this.provinceOptions = res.data?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  setup() {
    const PAYROLL_APP_STORE_MODULE_NAME = 'app-payroll';
    // Register module
    if (!store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
      store.registerModule(PAYROLL_APP_STORE_MODULE_NAME, payrollStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PAYROLL_APP_STORE_MODULE_NAME);
      }
    });
    function loadData() {
      store.dispatch('app-payroll/fetchPayrollDetailCompany', router.currentRoute.params.id);
    }
    loadData();
    return {
      loadData,

      provinceOptions: ref(null),
    };
  },
};
</script>
