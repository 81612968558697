<template>
  <!-- Table Container Card -->
  <b-card no-body class="mb-0 mt-1 p-2">
    <div class="text-center mb-1">
      <h4>Danh sách tài khoản iLương</h4>
      <!-- <b-row class="mb-1">
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Họ và tên</label>
          <b-form-input class="d-inline-block mr-1" :value="fullName" @input="debounceSearch" />
        </b-col>
      </b-row> -->
      <b-button variant="primary" type="submit" @click="handleOpenCreateAccountModal">
        Thêm tài khoản
      </b-button>
    </div>
    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="fetchListAccountCompany"
      responsive
      :fields="tableColumns"
      show-empty
      empty-text="Không tìm thấy kết quả"
    >
      <template #cell(transactionNote)="data">
        <div style="width: 300px" class="text-truncate">{{ data.item.transactionNote }}</div>
      </template>

      <template #cell(isActive)="data">
        <b-form-checkbox
          :checked="data.item.isActive"
          name="check-button"
          switch
          @input="(val) => handleUpdateActive(data.item, val)"
        />
      </template>
      <template #cell(isBoss)="data">
        <b-form-checkbox :checked="data.item.isBoss" disabled name="check-button" />
      </template>
      <template #cell(actions)="data">
        <b-button variant="outline-primary" @click="handleOpenEditAccountModal(data.item.id)"
          >Chỉnh sửa</b-button
        >
        <!-- <b-button variant="outline-danger" @click="handleDeleteAccount(data.item.id)">Xoá</b-button> -->
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalContract"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <b-modal
      ref="modal-delete-account"
      title="Xoá tài khoản"
      ok-title="Xác nhận"
      cancel-title="Huỷ"
      @ok="handleConfirmDeleteAccount"
      @reset="resetModal"
    >
      <p>Bạn có chắc chắn muốn xoá tài khoản này?</p>
    </b-modal>
    <b-modal
      ref="modal-account"
      :title="stateModalAccount === 'edit' ? 'Chỉnh sửa tài khoản' : 'Thêm tài khoản'"
      cancel-title="Huỷ"
      :no-close-on-backdrop="true"
      @ok="handleOk"
      @reset="resetModal"
      @hide="resetModal"
    >
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Username</label>
          <b-form-input
            v-model="username"
            :disabled="stateModalAccount === 'edit'"
            class="d-inline-block mr-1"
          />
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Password</label>
          <div class="d-flex align-items-center position-relative">
            <b-form-input
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              class="d-inline-block"
            />
            <feather-icon
              :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'"
              class="position-absolute"
              style="right: 7px"
              role="button"
              @click="() => (showPassword = !showPassword)"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Họ và tên</label>
          <b-form-input v-model="fullName" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Số điện thoại</label>
          <b-form-input v-model="phoneNumber" class="d-inline-block mr-1" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Giám đốc</label>
          <b-form-checkbox v-model="isBoss" /> </b-col
      ></b-row>
      <div v-if="isBoss">
        <b-row>
          <b-col cols="12" md="12" class="mb-md-0 mb-2">
            <label>Email<span class="required">*</span></label>
            <b-form-input v-model="email" type="email" class="d-inline-block mr-1" /> </b-col
        ></b-row>
        <b-row>
          <b-col cols="12" md="12" class="mb-md-0 mb-2">
            <label>CMND/CCCD<span class="required">*</span></label>
            <b-form-input v-model="nicNumber" class="d-inline-block mr-1" /> </b-col
        ></b-row>
        <b-row>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>Ngày cấp<span class="required">*</span></label>
            <b-form-datepicker
              id="salary-date"
              v-model="nicDateProvide"
              size="sm"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              locale="en-GB"
              style="z-index: 1000"
            />
          </b-col>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>Nơi cấp<span class="required">*</span></label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="provinceId"
              :options="provinceOptions"
              label="name"
              class="w-100"
              :reduce="(val) => val.gsoId"
              @input="(val) => (provinceId = val)"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- <b-modal
      id="modal-add-account"
      title="Thêm tài khoản"
      cancel-title="Huỷ"
      @ok="handleCreateAccount"
      @hide="resetModal"
    >
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Username<span class="required">*</span></label>
          <b-form-input v-model="username" class="d-inline-block mr-1" />
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Password<span class="required">*</span></label>
          <b-form-input v-model="password" type="password" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Họ và tên<span class="required">*</span></label>
          <b-form-input v-model="fullName" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Số điện thoại<span class="required">*</span></label>
          <b-form-input v-model="phoneNumber" class="d-inline-block mr-1" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Giám đốc</label>
          <b-form-checkbox v-model="isBoss" /> </b-col
      ></b-row>
      <div v-if="isBoss">
        <b-row>
          <b-col cols="12" md="12" class="mb-md-0 mb-2">
            <label>Email<span class="required">*</span></label>
            <b-form-input v-model="email" class="d-inline-block mr-1" /> </b-col
        ></b-row>
        <b-row>
          <b-col cols="12" md="12" class="mb-md-0 mb-2">
            <label>CMND/CCCD<span class="required">*</span></label>
            <b-form-input v-model="nicNumber" class="d-inline-block mr-1" /> </b-col
        ></b-row>
        <b-row>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>Ngày cấp<span class="required">*</span></label>
            <b-form-datepicker
              id="salary-date"
              v-model="nicDateProvide"
              size="sm"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              locale="en-GB"
              style="z-index: 1000"
            />
          </b-col>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>Nơi cấp<span class="required">*</span></label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="provinceId"
              :options="datasuggestProvince"
              label="name"
              class="w-100"
              :reduce="(val) => val.gsoId"
              @input="(val) => (provinceId = val)"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal> -->
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BFormDatepicker,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import router from '@/router';
import { numberWithCommas } from '@core/utils/utils';
import {
  getDetailAccountCompany,
  updateAccountCompany,
  deleteAccountCompany,
  createAccountCompany,
} from '@/apis/apiPayroll';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import usePayrollDetailSalaryList from './usePayrollCompanyDetailListAccount';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BFormDatepicker,
    BModal,
    vSelect,
  },
  props: {
    provinceOptions: {
      type: Array,
      default: null,
    },
  },
  setup() {
    const {
      fetchListAccountCompany,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      fullName,
    } = usePayrollDetailSalaryList();
    return {
      // Sidebar
      refUserListTable,
      fetchListAccountCompany,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      numberWithCommas,
      fullName,
    };
  },
  data() {
    return {
      debounce: null,
      username: '',
      password: undefined,
      fullName: '',
      phoneNumber: '',
      email: undefined,
      nicNumber: undefined,
      nicDateProvide: undefined,
      provinceId: undefined,
      company: '',
      isBoss: false,
      accountId: '',
      stateModalAccount: null,
      showPassword: false,
    };
  },
  methods: {
    resetModal() {
      this.username = '';
      this.password = undefined;
      this.fullName = '';
      this.phoneNumber = '';
      this.email = undefined;
      this.nicNumber = undefined;
      this.nicDateProvide = undefined;
      this.provinceId = undefined;
      this.company = '';
      this.isBoss = false;
      this.accountId = '';
    },
    handleCreateAccount() {
      createAccountCompany({
        username: this.username,
        password: this.password,
        phoneNumber: this.phoneNumber,
        fullName: this.fullName,
        companyId: router.currentRoute.params.id,
        isBoss: this.isBoss,
        email: this.email,
        nicNumber: this.nicNumber,
        nicDateProvide: this.nicDateProvide,
        provinceId: this.provinceId,
        isActive: true,
      })
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tạo thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tạo thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    handleConfirmEdit() {
      updateAccountCompany(this.accountId, {
        username: this.username,
        password: this.password || undefined,
        phoneNumber: this.phoneNumber,
        fullName: this.fullName,
        companyId: this.id,
        isBoss: this.isBoss,
        email: this.email,
        nicNumber: this.nicNumber,
        nicDateProvide: this.nicDateProvide,
        provinceId: this.provinceId,
        company: this.company,
      })
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chỉnh sửa thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chỉnh sửa thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    handleUpdateActive(item, val) {
      updateAccountCompany(item.id, {
        isActive: val,
        isBoss: item.isBoss,
      })
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thành công ',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thất bại ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    handleDeleteAccount(id) {
      this.$refs['modal-delete-account'].show();
      this.accountId = id;
    },
    handleConfirmDeleteAccount() {
      deleteAccountCompany(this.accountId)
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xoá thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xoá thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },

    handleOpenEditAccountModal(id) {
      this.stateModalAccount = 'edit';
      this.$refs['modal-account'].show();
      this.getDetailAccountCompany(id);
      this.accountId = id;
    },
    handleOpenCreateAccountModal() {
      this.stateModalAccount = 'create';
      this.$refs['modal-account'].show();
    },
    handleOk() {
      if (this.stateModalAccount === 'edit') {
        this.handleConfirmEdit();
      } else {
        this.handleCreateAccount();
      }
    },
    getDetailAccountCompany(id) {
      getDetailAccountCompany(id)
        .then((res) => {
          const { data } = res?.data;
          this.username = data.username;
          this.fullName = data.fullName;
          this.phoneNumber = data.phoneNumber;
          this.email = data.email;
          this.nicNumber = data.nicNumber;
          this.nicDateProvide = data.nicDateProvide;
          this.provinceId = data.provinceId;
          this.isBoss = data.isBoss;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.required {
  color: red;
  margin-left: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
