<template>
  <!-- Table Container Card -->
  <b-card no-body class="mb-0 mt-1 p-2">
    <div class="text-center mb-1">
      <h4>Thông tin thẩm định</h4>
    </div>

    <div>
      <h4>{{ $t('Director info') }}</h4>
      <b-row>
        <b-col xs="12" lg="6">
          <div class="d-flex align-items-center py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1"
              >{{ $t('Full name') }}
            </label>
            <div>{{ companyDetail.boss ? companyDetail.boss.fullName : '' }}</div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex align-items-center py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Phone number')
            }}</label>
            <div>{{ companyDetail.boss ? companyDetail.boss.phoneNumber : '' }}</div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex align-items-center py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Email')
            }}</label>
            <div>{{ companyDetail.boss ? companyDetail.boss.email : '' }}</div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex align-items-center py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Nic number')
            }}</label>
            <div>{{ companyDetail.boss ? companyDetail.boss.nicNumber : '' }}</div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex align-items-center py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Nic provide date')
            }}</label>
            <format-time
              v-if="companyDetail.boss"
              :time="companyDetail.boss.nicProvideDate"
              format-type="DD/MM/YYYY"
            />
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex align-items-center py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Nic place')
            }}</label>
            <div>{{ provinceText }}</div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Nic front image')
            }}</label>
            <minimize-image
              v-if="companyDetail.boss"
              :url="companyDetail.boss.nicFrontImage"
              :width="200"
            />
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Nic back image')
            }}</label>
            <minimize-image
              v-if="companyDetail.boss"
              :url="companyDetail.boss.nicBackImage"
              :width="200"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Spacer -->
    <hr />

    <div class="mt-2 general-info">
      <h4>Các chứng từ</h4>
      <b-row>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Financial report files')
            }}</label>

            <div class="flex flex-column">
              <hyper-link
                v-for="(file, index) in companyDetail.financialReportFiles"
                :key="index"
                :label="file.name"
                truncate
                :url="file.url"
                style="margin: 0"
                class="flex-2"
              />
            </div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Business license files')
            }}</label>
            <div class="flex flex-column">
              <hyper-link
                v-for="(file, index) in companyDetail.businessLicenseFiles"
                :key="index"
                :label="file.name"
                truncate
                :url="file.url"
                style="margin: 0"
              />
            </div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Statement files')
            }}</label>

            <div class="flex flex-column">
              <hyper-link
                v-for="(file, index) in companyDetail.statementFiles"
                :key="index"
                :label="file.name"
                truncate
                :url="file.url"
                style="margin: 0"
              />
            </div>
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Payroll file')
            }}</label>

            <hyper-link
              v-if="companyDetail.payrollFile"
              :label="companyDetail.payrollFile.name"
              :url="companyDetail.payrollFile.url"
              style="margin: 0"
            />
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Recivable comming file')
            }}</label>

            <hyper-link
              v-if="companyDetail.receivablesComingFile"
              :label="companyDetail.receivablesComingFile.name"
              :url="companyDetail.receivablesComingFile.url"
              style="margin: 0"
            />
          </div>
        </b-col>
        <b-col xs="12" lg="6">
          <div class="d-flex flex-column py-1">
            <label style="margin-bottom: 0" class="flex-1 font-weight-bold mr-1">{{
              $t('Other files')
            }}</label>
            <div class="flex flex-column">
              <hyper-link
                v-for="(file, index) in companyDetail.otherFiles"
                :key="index"
                :label="file.name"
                truncate
                :url="file.url"
                style="margin: 0"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="!companyDetail.isVerify" class="pt-3 text-center">
      <loader-button
        v-b-modal.modal-confirm-verify
        type="submit"
        variant="primary"
        spiner-style="width: 1rem; height: 1rem"
        spiner-variant="light"
        :loading="submitting"
      >
        {{ $t('Verify') }}
      </loader-button>
    </div>
    <b-modal
      id="modal-confirm-verify"
      title="Xác thực công ty"
      ok-title="Xác thực"
      cancel-title="Huỷ"
      @ok="confirmVerify"
    >
      <p>Bạn có chắc chắn muốn xác thực công ty này?</p>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BModal } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { numberWithCommas } from '@core/utils/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import HyperLink from '@/components/HyperLink.vue';
import MinimizeImage from '@/components/MinimizeImage.vue';
import FormatTime from '@core/components/format-time/FormatTime.vue';
import { mapGetters } from 'vuex';
import { companyVerify } from '@/apis/apiPayroll';
import LoaderButton from '@/components/LoaderButton.vue';
import usePayrollDetailSalaryList from './usePayrollCompanyDetailListAccount';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    vSelect,
    MinimizeImage,
    HyperLink,
    FormatTime,
    LoaderButton,
  },
  props: {
    provinceOptions: {
      type: Array,
      default: null,
    },
  },
  setup() {
    const {
      fetchListAccountCompany,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      fullName,
    } = usePayrollDetailSalaryList();
    return {
      // Sidebar
      refUserListTable,
      fetchListAccountCompany,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      numberWithCommas,
      fullName,
    };
  },
  data() {
    return {
      submitting: false,

      provinceText: '',
    };
  },
  computed: {
    ...mapGetters({
      companyDetail: 'app-payroll/getCompanyDetailInfor',
    }),
  },
  watch: {
    provinceOptions() {
      this.findProvinceText();
    },
    companyDetail() {
      this.findProvinceText();
    },
  },
  methods: {
    findProvinceText() {
      if (
        this.companyDetail.boss?.nicPlace &&
        this.provinceOptions &&
        this.provinceOptions.length > 0
      ) {
        const found = this.provinceOptions.find(
          (item) => this.companyDetail.boss.nicPlace === item.gsoId,
        );
        this.provinceText = found?.name;
      }
    },
    confirmVerify() {
      this.submitting = true;
      const companyId = this.$route.params.id;
      companyVerify(companyId)
        .then(() => {
          this.submitting = false;
          this.companyDetail.isVerify = true;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xác thực công ty thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          this.submitting = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xác thực công ty thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 14px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
