<template>
  <div>{{ (time && moment(time).format(formatType)) || '_' }}</div>
</template>
<script>
import * as moment from 'moment';

export default {
  props: {
    time: {
      type: String,
      default: '',
    },
    formatType: {
      type: String,
      default: 'DD/MM/YYYY - HH:mm',
    },
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
