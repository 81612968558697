import {
  payrollList,
  payrollPaymentList,
  payrollDetail,
  payrollDetailContractList,
  payrollDetailRepaymentHistory,
  getListAccountCompany,
  companyList,
  companyDetail,
} from '@/apis/apiPayroll';

export default {
  namespaced: true,
  state: () => ({
    payrollDetailInfor: {},
    companyDetailInfor: {},
  }),
  getters: {
    getPayrollDetailInfor(state) {
      return state.payrollDetailInfor;
    },
    getCompanyDetailInfor(state) {
      return state.companyDetailInfor;
    },
  },
  mutations: {
    setPayrollDetailInfor(state, data) {
      state.payrollDetailInfor = data;
    },
    setCompanyDetailInfor(state, data) {
      state.companyDetailInfor = data;
    },
  },
  actions: {
    fetchPayrolls(ctx, condition) {
      return new Promise((resolve, reject) => {
        payrollList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchPayrollDetail(ctx, condition) {
      return new Promise((resolve, reject) => {
        payrollDetail(condition)
          .then((response) => {
            ctx.commit('setPayrollDetailInfor', response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchPayrollDetailContractList(ctx, condition) {
      return new Promise((resolve, reject) => {
        const { id } = condition;
        delete condition.id;
        payrollDetailContractList(id, condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchPayrollDetailRepaymentHistory(ctx, condition) {
      return new Promise((resolve, reject) => {
        const { id } = condition;
        delete condition.id;
        payrollDetailRepaymentHistory(id, condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchPayrollsPayment(ctx, condition) {
      return new Promise((resolve, reject) => {
        payrollPaymentList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchPayrollCompanys(ctx, condition) {
      return new Promise((resolve, reject) => {
        companyList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchPayrollDetailCompany({ commit }, condition) {
      return new Promise((resolve, reject) => {
        companyDetail(condition)
          .then((response) => {
            commit('setCompanyDetailInfor', response.data?.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchListAccountCompany(ctx, condition) {
      return new Promise((resolve, reject) => {
        getListAccountCompany(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
